<template>
    <div style="width: 100%; height: 100%; position:absolute; background-color: black;" id="padreCanvas">
        <div class="background-img" id="bg-se">
            <div class="loader">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
        </div>
        <canvas id="unity-canvas" style="width: 100%; height: 100%; background: #231F20;" class="alturase"></canvas>

    </div>
</template>
<script>
import firebase from 'firebase'
import { db } from '../../../plugins/firebase'
import 'firebase/firestore'
import 'firebase/auth'


export default {
    
    data() {
        return {
           token:'', 
        }
    },
    created(){
        // console.log(this.$store.state.user);
        let role = this.$store.state.user.role;
        let uid = this.$store.state.user.uid;
        if (role == 'student') {
            let user = { uid: uid, role: "Student" }
            const updatetAdmin = firebase.functions().httpsCallable('IsExpirate')
            updatetAdmin(user)
                .then((result) => {
                // console.log("*******************************");
                //  console.log(result);
                    if (result.data.isVencido)
                         location.href = "/license-finish";
                });
        }
        window.addEventListener("resize", this.eventResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.eventResize);
    },
    async mounted() {
        this.token  = this.$store.state.user.token
        var elemento = document.querySelectorAll(".alturase");
        elemento[0].removeAttribute("height");
        elemento[0].removeAttribute("width");
        var height = window.innerHeight;
        // var width = window.innerWidth;
        // elemento[0].style.height=height+'px';
        // elemento[0].style.width=width+'px';
        let EsLite = "VersionNomal";
        let DeviceFound = this.GetOS();
        console.log(DeviceFound);
        if (this.EsMobile() || DeviceFound == "Android" || DeviceFound == "BlackBerry" || DeviceFound == "Chrome OS" || DeviceFound == "iPad" || DeviceFound == "iPhone") {
            EsLite = "VersionLite";
        }
        if (DeviceFound == 'macOS' && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
            EsLite = "VersionLite";
        }
        // console.log(this.EsMobile());
        // console.log(DeviceFound);
        // console.log(navigator.platform);
        // console.log(navigator.maxTouchPoints);
        this.AjustarBordes();
        var unityGame;
        let DataStudent = await  db.collection("Students").doc(this.$store.state.user.uid).get();
        console.log(DataStudent.data());
        let LeeV2 = false;
        if (Object.prototype.hasOwnProperty.call(DataStudent.data(), 'LeeLeeV2')) {
          if (DataStudent.data().LeeLeeV2 == true) {
            LeeV2 = true;
          }
        }
        if (LeeV2) { // Lee Lee aventura espacial
            let responsiveScript = document.createElement('script')
            responsiveScript.setAttribute('src', '/seL2/Build/SEL203092024d.loader.js')
            document.body.appendChild(responsiveScript)
            responsiveScript.onload = () => {
                createUnityInstance(document.querySelector("#unity-canvas"), {
                    dataUrl: "/seL2/Build/SEL203092024d.data",
                    frameworkUrl: "/seL2/Build/SEL203092024d.framework.js",
                    codeUrl: "/seL2/Build/SEL203092024d.wasm",
                    streamingAssetsUrl: "/seL2/StreamingAssets",
                    companyName: "escueladelectura",
                    productName: "LeeLee SE",
                    productVersion: "1.0.16",
                })
                .then((instance) =>
                {
                    window.unityInstance = instance;
                    window.firebase = firebase;
                    // this.unityInstance = instance;
                    unityGame = instance;
                    let me = this;
                    var bg = document.getElementById('bg-se');
                    if(bg != null){
                        bg.classList.add('d-none');
                    }
                    setTimeout(function () { console.log(EsLite); unityInstance.SendMessage('LogoControlador', 'WebGLVersionLite', EsLite); console.log(me.token) }, 1000);
                    
                })
                .catch((message) => { console.warn(message); });
            }
        }else{
            let responsiveScript = document.createElement('script')
            responsiveScript.setAttribute('src', '/se/Build/SE25042024.loader.js')
            document.body.appendChild(responsiveScript)
            responsiveScript.onload = () => {
                createUnityInstance(document.querySelector("#unity-canvas"), {
                    dataUrl: "/se/Build/SE25042024.data",
                    frameworkUrl: "/se/Build/SE25042024.framework.js",
                    codeUrl: "/se/Build/SE25042024.wasm",
                    streamingAssetsUrl: "/se/StreamingAssets",
                    companyName: "escueladelectura",
                    productName: "LeeLee SE",
                    productVersion: "1.0.16",
                })
                .then((instance) =>
                {
                    window.unityInstance = instance;
                    window.firebase = firebase;
                    // this.unityInstance = instance;
                    unityGame = instance;
                    let me = this;
                    var bg = document.getElementById('bg-se');
                    if(bg != null){
                        bg.classList.add('d-none');
                    }
                    setTimeout(function () { console.log(EsLite); unityInstance.SendMessage('GestorJuego', 'WebGLVersionLite', EsLite); console.log(me.token) }, 1000);
                    
                })
                .catch((message) => { console.warn(message); });
            }
        }

        
    },
    methods: {
        AjustarBordes(){
            let link = document.querySelector('#unity-canvas');
            if (link) {
                let height = window.innerHeight;
                let width = window.innerWidth;
                if (height>width) {
                    let porcentaje = Math.round(((height-width)*100)/height);
                    // console.log(porcentaje);
                    document.getElementById("padreCanvas").style.padding = porcentaje+"%"+ 0;
                }else{
                    document.getElementById("padreCanvas").style.padding = 0+ 0;
                    link.style.height=height+'px';
                    // console.log("se reestablecio");
                }
            }
        },
        eventResize(e){
            this.AjustarBordes();
        },
        EsMobile(){
            var check = false;
            (function(a){
                if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
                check = true;
            })(navigator.userAgent||navigator.vendor||window.opera);
            return check;
        },
        GetOS(){
            let device = "Unknown";
            const ua = {
                "Generic Linux": /Linux/i,
                "Android": /Android/i,
                "BlackBerry": /BlackBerry/i,
                "Bluebird": /EF500/i,
                "Chrome OS": /CrOS/i,
                "Datalogic": /DL-AXIS/i,
                "Honeywell": /CT50/i,
                "iPad": /iPad/i,
                "iPhone": /iPhone/i,
                "iPod": /iPod/i,
                "macOS": /Macintosh/i,
                "Windows": /IEMobile|Windows/i,
                "Zebra": /TC70|TC55/i,
            }
            Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
            return device;
        }
    }
}
</script>
<style>
  .background-img{
    width: 100%;
    height: calc(100%);
    background-image: url('/AperturaLeeLee.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    z-index: 1;
  }
  .loader{
    position: absolute;
    bottom: 50px;
    left: 50%;
  }
</style>